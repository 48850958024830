import React from "react";
import { ActionImpl, ActionId } from "kbar";

interface IResultITemProps {
    action: ActionImpl;
    active: boolean;
    currentRootActionId: ActionId;
}
export const ResultItem = React.forwardRef<HTMLDivElement, IResultITemProps>(
    ({ action, active, currentRootActionId }, ref) => {
        const ancestors = React.useMemo(() => {
            if (!currentRootActionId) return action.ancestors;
            const index = action.ancestors.findIndex(
                (ancestor) => ancestor.id === currentRootActionId,
            );
            return action.ancestors.slice(index + 1);
        }, [action.ancestors, currentRootActionId]);

        return (
            <div
                ref={ref}
                style={{
                    padding: "12px 16px",
                    background: active ? "rgba(0 0 0 / 0.05)" : "transparent",
                    borderLeft: `2px solid ${
                        active ? "rgb(28 28 29)" : "transparent"
                    }`,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                        fontSize: 14,
                    }}
                >
                    {action.icon && action.icon}
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div>
                            {ancestors.length > 0 &&
                                ancestors.map((ancestor) => (
                                    <React.Fragment key={ancestor.id}>
                                        <span
                                            style={{
                                                opacity: 0.5,
                                                marginRight: 8,
                                            }}
                                        >
                                            {ancestor.name}
                                        </span>
                                        <span
                                            style={{
                                                marginRight: 8,
                                            }}
                                        >
                                            &rsaquo;
                                        </span>
                                    </React.Fragment>
                                ))}
                            <span
                                style={{
                                    color:
                                        action.name.toLocaleUpperCase() ===
                                        "DELETE"
                                            ? "red"
                                            : "black",
                                }}
                            >
                                {action.name}
                            </span>
                        </div>
                        {action.subtitle && (
                            <span style={{ fontSize: 12 }}>
                                {action.subtitle}
                            </span>
                        )}
                    </div>
                </div>
                {action.shortcut?.length ? (
                    <div
                        aria-hidden
                        style={{
                            display: "grid",
                            gridAutoFlow: "column",
                            gap: "4px",
                        }}
                    >
                        {action.shortcut.map((sc) => (
                            <kbd
                                key={sc}
                                style={{
                                    padding: "4px 6px",
                                    background: "rgba(0 0 0 / .1)",
                                    borderRadius: "4px",
                                    fontSize: 14,
                                }}
                            >
                                {sc}
                            </kbd>
                        ))}
                    </div>
                ) : null}
            </div>
        );
    },
);

ResultItem.displayName = "ResultItem";
